* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::-moz-focus-inner {
    border: 0; // removes dotted outline around text when element is focused
  }
}

html,
body,
#root {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

body div {
  // DP-2807 and DP-2818 for iOS 12
  -webkit-transform: translate3d(0, 0, 0);
}

body.no-scroll {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

button,
input,
select {
  appearance: none;
  background-color: transparent;
  border: none;
  display: block;
  margin: auto;
}

input,
select,
textarea,
button {
  font-family: inherit;
}

button {
  cursor: pointer;
  padding: 0;
  user-select: none;
  transform: translate3d(0, 0, 0);
  touch-action: manipulation;
}

button:disabled,
button[aria-disabled='true'] {
  cursor: default;
  background-color: #979797;
  opacity: 0.7;
  box-shadow: none;
}

button:hover,
button:active {
  box-shadow: none;
}

a {
  margin: 0 0 10px 0;
  padding: 0;
  display: inline-block;
}

section {
  position: relative;
}

img {
  max-width: 100%;
  max-height: 100%;
  user-select: none;
  -webkit-user-drag: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
li,
ul,
ol {
  margin: 0;
  padding: 0 0 10px 0;
  font-weight: normal;

  &:empty {
    display: none;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

ul {
  list-style-type: none;
}

path {
  fill: inherit;
}

figure {
  margin: 0;
  padding: 0;
}

img {
  display: block;
}

button,
a,
input {
  &:focus {
    outline: 2px solid #3b99fc;
    outline-offset: 2px;
  }
}

input[type='checkbox'] {
  position: absolute;
  opacity: 0.1;
  top: 0;
  left: 0;
}

input[type='checkbox']:focus + label {
  outline: 2px solid #3b99fc;
  outline-offset: 2px;
}

dialog {
  padding: 0;
  margin: 0;
  border: none;
}

/* All properties must be !important as this is not supposed to be displayed */
.screen-reader-text {
  position: absolute !important;
  visibility: visible !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  clip: rect(0px,  0px,  0px,  0px) !important;
  clip-path: polygon(0px 0px,  0px 0px,  0px 0px,  0px 0px) !important;
  white-space: nowrap !important;
  font-size: 1px !important;
}
